<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: 'App'
}


</script>

<style scoped>
@import './assets/css/normalize.css';
@import './assets/css/webflow.css';
@import './assets/css/custom.css';

</style>
