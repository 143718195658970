

import './assets/js/webflow.js';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//Vue.config.productionTip = false

const app = createApp(App).use(router).use(store).mount('#app')


store.dispatch('loginFromCookie');
store.dispatch('getFavoritesFromCookie');
store.dispatch('getWorkshops');

setTimeout(() => { store.dispatch('getUsers') }, 500);
setInterval(() => { store.dispatch('getMessages') }, 1000);
setInterval(() => { store.dispatch('getWorkshops') }, 60000);
setInterval(() => { store.dispatch('getUsers') }, 60000);

if (store.getters.isAuthenticated != true){
  console.log("test");
  router.replace({ name: "Login" })
}

setTimeout(() => {window.Webflow.destroy();window.Webflow.ready(); }, 600);

