<template>

    <div>
  
        <Header></Header>

        <PremiumHero></PremiumHero>

  
        <MMKPremiumContent></MMKPremiumContent>

        <Banner></Banner>
    
        <Partners></Partners>

        <Footer></Footer>

  </div>

</template>

<script>
import Header from '../components/includes/Header.vue'
import Footer from '../components/includes/Footer.vue'
import Partners from '../components/subcomponents/Partners.vue'
import MMKPremiumContent from '../components/subcomponents/MMKPremiumContent.vue'
import Banner from '../components/subcomponents/Banner.vue'
import PremiumHero from '../components/subcomponents/PremiumHero.vue'


export default {
    name: 'MMKPremium',
    components: {
        Header,
        Footer,
        Partners,
        MMKPremiumContent,
        Banner,
        PremiumHero
    }

}
</script>

<style>

</style>