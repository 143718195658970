<template>

   <div> 
    
    <div style="margin-top:50px;margin-left:10px">
        <div class="workshops-content">
            <div class="big-content-div">
                <div class="thankyou-content">
                    <div class="thank-you">
                        <div class="thank-you-title">Welcome {{ name }}!</div>
                        <div class="thank-you-txt">
                            <p>Thank you for joining the <b>6<sup>th</sup> edition of Booking Manager Summit!</b> Let's continue to Shape the Future of Yacht Charter together!</p>

                            <p>The goal of Booking Manager Summit is empowering charter industry leaders with knowledge, insights and ideas to sustain growth and take the industry to the next level!</p>

                            <p>During the Summit, you can leverage <b>connecting with all of the</b> Summit participants, discuss your current business dilemmas and discover nuanced solutions, 
                            as well as <b>build new partnerships</b> or solidify existing ones. To help you attain your individual business success strategy, you will be able to gather practical knowledge and expertise of <b>over 50 sessions</b>, 
                            implementing them into a tailor made solution unique to fit your own business needs.</p>

                            <p>Check the schedule of Booking Manager Summit 2024 and pick the topics best suited to help you achieve clarity for growing your business!</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </div>

</template>

<script>
export default {
    name: 'ThankYou',
    computed: {
      name () {
        return !this.$store.getters.user ? false : this.$store.getters.user.name
      }
    },
}
</script>

<style>

</style>