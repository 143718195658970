//import { set } from 'core-js/fn/dict';
import Vue from 'vue'
import {use} from 'vue'
import Vuex from 'vuex'
import router from './router'
import Summit from './summit.js'

/*
function getRoomForId(rooms,id){
  console.log(id);
  rooms.forEach(room => {if (room.id==id){return room} });
  return ({});
};*/

var summit = new Summit();

function getRoomForName(rooms,name){
  var foundRoom = {id:0,name:"Lobby"};
  rooms.forEach(room => {if (room.name==name){console.log("found it!!! " +room.id);foundRoom = room} });
  return foundRoom;
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


export default new Vuex.Store({
  router,
  summit,
  state: {
    user:  {
			id:-1,
			email:'unknown@',
			status:'offline',
			name:'unknown',
			surname:'unknown',
			token:null,
      level:'Visitor'
    },
    rooms: [
      {id:0,name:'Lobby',video:'',zoom:''},
      {id:1,name:'Maksimir',video:'',zoom:''},
      {id:2,name:'Jelenovac',video:'',zoom:''},
      {id:3,name:'Tuškanac',video:'',zoom:''},
      {id:4,name:'Zelengaj',video:'',zoom:''},
      {id:5,name:'Virtual',video:'',zoom:''},
      {id:10,name:'Stage 1',zoom:'',video:'https://www.youtube.com/embed/k1bffWOBbsQ?si=YisNNvqrjMEWzir8&autoplay=1',video2:''},
      {id:11,name:'Stage 2',zoom:'',video:'https://www.youtube.com/embed/QZodo5b2Zg8?si=WC9vLIBBU3X6UyYT&autoplay=1',video2:''},
      {id:12,name:'Stage 3',zoom:'',video:'https://www.youtube.com/embed/TGtxzwEpm1I?si=5rWcT-rXr8qbKhqu&autoplay=1',video2:''},
    ],
    room: {id:0,name:'Lobby'},
    messages: [],
    workshops: [],
    users: [],
    selectedWorkshop: {},
    page: "Lobby",
    favorites: [],
    errorMessage: "",
  },

  mutations: {
     storeUser (state, user) {
      state.user = user
    },
    storeUsers (state, users) {
      state.users = users
    },
    storeMessages (state, messages) {
      state.messages = messages
    },
    storeWorkshops (state, workshops) {
      state.workshops = workshops
    }, 
    storeSelectedWorkshop (state,workshop){
      state.selectedWorkshop = workshop
    },
    storePage (state,page){
      state.page = page
    },
    setErrorMessages(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    storeFavorites (state,favorites){
      state.favorites = favorites
      setCookie("favorites",state.favorites+"",180);
    },
    storeSummitPlus(state,payload){
      state.summitPlus = payload
    }
  },

  actions: {
    loginFromCookie({commit,state}){
      console.log("Logging in from cookie!");
      var token = "Bearer "+getCookie("token");
      //console.log("token "+token);
      if (token == null || token=="") return
      fetch(summit.apiBase+'/user',{
        method: 'GET',
        headers: {'Content-Type': 'application/json',
              'Authorization':token}
        })
      .then(response => {
          if (response.status === 204) {
            console.log("response is null", response)
              return null; 
          } else {
              console.log("response is not null", response)
              return response.json(); 
          }
      })
      .then(data => {
          if (data) {
              commit('storeUser', data); 
          }
      })
      .catch(error => {
          console.error("Error logging in from cookie:", error); // Handle potential fetch errors
      })
    },
    getFavoritesFromCookie({commit}){
        var favs = getCookie("favorites");
        if (favs!=null&&favs!=""){
          var a = favs.split(",");
          var result = a.map(x => {return parseInt(x, 10); });
          commit('storeFavorites',result);
        }
    },
    login({commit,state},auth){
      /*console.log("Username "+username);
      console.log("Password "+password);*/
      //console.log("URL "+state.apiBase);
      //const auth = {username:username,password:password};
      fetch(summit.apiBase+'/login',{
        method: 'POST',
        body: JSON.stringify(auth) ,
        headers: {'Content-Type': 'application/json'}
        }).then(
          response => response.json())
      .then(data => {

       // console.log(data);
        commit('storeUser',data);
        if (data!=null && data.token!=null){
          setCookie("token",data.token,160)
        }
      }).catch((error) => {
        commit("setErrorMessages", "Please try it again. Wrong email or password.");
        console.log(error)
      });
    },
    logout({commit}) {
      console.log("Logging out..")
      commit('storeUser', {
          id:-1,
          email:null,
          status:null,
          name:null,
          surname:null,
          token:null
      });
      setCookie("token","");
      router.replace({ name: "Login" })
    }, 

    sendMessage({state,getters},message){
      const id = getters.activeRoom.id
      const message1 = {to:id,from:state.user.id,message:message};
      const body1 = JSON.stringify(message1)
      //console.log(body1);
      fetch(summit.apiBase+'/sendMessage',{
        method: 'POST',
        body: body1 ,
        headers: {'Content-Type': 'application/json',
              'Authorization':getters.token}
        })
        .then(response => {
          if (response.status === 204) {
              return null; 
          } else {
              return response.json(); 
          }
        })
        .then(data => {
          console.log(data);
        });
    },

    getMessages({state,getters,commit}){
      if (!getters.isAuthenticated){
        //console.log("Not logged in!");
        return;
      }

      summit.getMessages(getters.activeRoom.id,getters.token)
      .then(data => {
        if (data.length!=state.messages.length){
         // console.log("new Message!!!!!!")
          commit('storeMessages', data )
        }
      });
    },

    getWorkshops({state,commit}){
      fetch(summit.apiBase+'/workshops',{
        method: 'GET',
        headers: {'Content-Type': 'application/json',}
        }).then(response => response.json())
      .then(data => {
        commit('storeWorkshops',data)
        var sel = state.selectedWorkshop
        if (sel!=null){
          data.forEach(w => {if(w.id==sel.id){commit('storeSelectedWorkshop',w)}});
        }


      });
    },

    getUsers({state,commit,getters}){

      if (!getters.isAuthenticated){
        //console.log("Not logged in, cannot fetch users");
        return;
      }

      fetch(summit.apiBase+'/users',{
        method: 'POST',
        headers: {'Content-Type': 'application/json',
        'Authorization':getters.token}
        }).then(response => response.json())
      .then(data => {
        //console.log(data);
        commit('storeUsers',data)
      });
    }

  },
  getters: {
    user (state) {
      return state.user
    },
    users (state) {
      return state.users
    },
    isAuthenticated (state) {
      return state.user.token !== null
    },
    token (state){
      return "Bearer "+state.user.token
    },
    messages (state){
      return state.messages;
    },
    workshops (state){
      return state.workshops;
    },
    liveNow(state) {
      return state.workshops.filter(w => w.liveNow==true&&w.tag!='Premium' );
    },
    selectedWorkshop (state) {
      return state.selectedWorkshop;
    },
    page (state){
      return state.page;
    },
    favorites (state){
      return state.favorites;
    },
    errorMessages (state){
      return state.errorMessage;
    },
    activeRoom (state){
      var pageName = state.page;
      if(pageName == "Workshop"){
        var currentWorkshop = state.selectedWorkshop;
        if (currentWorkshop.room != null){
          return getRoomForName(state.rooms,currentWorkshop.room);
        }
      }
      return getRoomForName(state.rooms,"Lobby");
      
      //return state.room;
    }
  }
})