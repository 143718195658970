<template>
  
    <div class="workshop-content-right">
        <div class="chat-div-big">
          <div class="chat-div-premium">
            <div class="chat-main-info">
              <div class="chat-title">Live Chat</div>
              <div class="online-users"><img src="images/users.png" loading="lazy" width="24" alt="">
                <div class="users-number">25</div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Rita Bradshaw (NSS)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Adam E. Burns (Adriatic Sailing)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="text-block-29-red">Daniel Disney</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Jason Barton (NSS)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Jason Barton (NSS)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Adam E. Burns (Adriatic Sailing)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Adam E. Burns (ACI)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Jason Barton(ACI)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Adam E. Burns (Ocean Sailing House)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="online-member">
              <div class="live-now-content">
                <div class="live-now-left">
                  <div class="green-chat-dot"></div>
                  <div class="live-text">
                    <div class="chat-text-1">Adam E. Burns (ACI)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-exhibitors">
            <div class="title-exhibitors">exhibitors available for chat</div>
            <div class="exhibitors-div">
              <div class="exhibitor">
                <div class="exhibitor-text">
                  <div>Mario Mamić</div>
                  <div>(MMK Booking Manager)</div>
                </div><a href="#" class="chat-btn w-button">chat</a></div>
              <div class="exhibitor">
                <div class="exhibitor-text">
                  <div>John Doe</div>
                  <div>(MMK Booking Manager)</div>
                </div><a href="#" class="chat-btn w-button">chat</a></div>
            </div>
          </div>
        </div>
      </div>
    
</template>

<script>
export default {

}
</script>

<style>

</style>