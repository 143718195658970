import Vue from 'vue'
import {use} from 'vue'
import VueRouter from 'vue-router'
import { createWebHistory, createRouter } from "vue-router";
import Login from '../views/Login.vue'
import Lobby from '../views/Lobby.vue'
import Workshop from '../views/Workshop.vue'
import MMKPremium from '../views/MMKPremium.vue'
import store from '../store'

//use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Login',
    name: 'Login2',
    component: Login
  },
  {
    path: '/lobby',
    name: 'Lobby',
    component: Lobby,
    // beforeEnter(to, from, next) {
    //   if (store.state.idToken) {
    //     next()
    //   } else {
    //     next('/')
    //   }
    // }
    // meta: {requiresAuth: true}
  },
  {
    path: '/workshop',
    name: 'Workshop',
    component: Workshop,
    // meta: {requiresAuth: true}
  },
  {
    path: '/mmkpremium',
    name: 'MMKPremium',
    component: MMKPremium,
    // meta: {requiresAuth: true}
  }
  
]

const router = createRouter({
  //store,
  history: createWebHistory(),
  routes
});

/*
,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
*/

router.beforeEach((to, from, next) => {
  store.commit('storePage',to.name);
  store.commit('storeMessages',[]);
  setTimeout(() => {window.Webflow.destroy();window.Webflow.ready(); }, 600);
  next();
});

router.afterEach((to, from) => {
  //console.log("Arrived at "+to.name);
  if (to.name=="Workshop"){
    setTimeout(() => {store.dispatch('getUsers'); }, 2000);
    
  }
});

// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
//   // checks if this route has meta with auth
//   const isAuthenticated = firebase.auth().currentUser
//   if(requiresAuth && !isAuthenticated){
//     next('/')
//   } else {
//     next();
//   }
// })

export default router
