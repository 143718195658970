export const Minxin = {
    methods:{
        goToSections(sectionRef){
            let element = document.querySelector(`#${sectionRef}`);
            element.click();
            element.scrollIntoView({behavior: "smooth" });
        },
        formatDate(workshop) {
            var d = new Date(workshop.date)
            return d.getDate()+" Nov";
        }

    }
}