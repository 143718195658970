<template>
    
    <div>


  <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" class="navbar w-nav">
      <div class="nav-div"><a href="/#/lobby" aria-current="page" class="logo-image w-inline-block w--current"><img src="images/logo.svg" loading="lazy" height="50" alt=""></a>
        <nav role="navigation" class="nav-menu w-nav-menu">
          <LiveNow></LiveNow>
          
          <!-- nav chat
          <div class="nav-inner-div nav-chat-mobile">
            <div data-hover="" data-delay="0" class="nav-drop w-dropdown">
                    <div class="nav-chat w-dropdown-toggle">
                      <img src="images/icon_chat.svg" loading="lazy" width="20" alt="">
                      <div class="nav-text">chat</div>
                    </div>
                    <nav class="chat-list w-dropdown-list">
                      <div id="navChatWindow" class="chat-div-nav">
                        <div class="chat-main-info">
                          <div class="chat-title">Messages</div>
                          <div class="online-users"><img src="images/users.png" loading="lazy" width="24" alt="">
                            <div class="users-number">{{ users.length }}</div>
                          </div>
                        </div>
                        <div @click="showPopup" class="chat-view clicker">
                          <div class="live-now-content">
                            <div class="live-now-left">
                              <div class="green-chat-dot"></div>
                              <div class="live-text">
                                <div class="chat-text-1">Rita Bradshaw (ACI)</div>
                                <div class="chat-text-2">Hi! How are you?</div>
                              </div>
                            </div>
                          </div>
                        </div>                                                              
                      </div>
                    </nav>
                  </div>
          </div>
           nav chat -->

          <div class="nav-inner-div">
            <div @click.prevent="goToSection('schedule')" class="nav-schedule"><a href="#" class="nav-link w-inline-block"><img src="images/icon_schedule.svg" loading="lazy" width="20" alt=""><div class="nav-text">schedule</div></a></div>
            <!-- <div @click.prevent="goToSection('summit-plus')" class="nav-summit-plus"><a href="#" class="nav-link w-inline-block"><div class="nav-text">summit+</div></a></div> -->
          
          </div>
          <User v-if="auth"></User>
        </nav>
        <div class="menu-button w-nav-button">
          <div class="icon w-icon-nav-menu"></div>
        </div>
      </div>
  </div>

  

  <!-- main chat -->
  <div class="chat" id="mainChat">
      <div class="chat-header">
        <div style="flex-grow:1" class="text-block-10">Live Chat {{activeRoom.name}}</div>
        <div class="div-block-22 clicker" @click="openLiveUsers()"><img src="images/users_icon_green.svg" loading="lazy" width="24" alt="">
          <div class="text-block-13">{{ usersInActiveRoom.length }} online</div>
        </div>
        <div class="close clicker" @click="toggleMobileChat()">
          <img src="images/close_black.svg" loading="lazy" alt=""></div>
      </div>
      <div id="chatContentScroll" class="chat-content">
        <div class="popup-time"></div>

        <div v-for="msg in messages" v-bind:key="msg.id" :class="'level-'+msg.level">
          <div v-if="msg.from==authUserId" class="message message2" >
            <div class="msg-content2">
              <div class="msg-text2">{{msg.message}}</div>
            </div>
          </div>

          <div v-else class="message message1">
            <div class="msg-content"  >
              <div class="msg-sender">{{msg.fromName}}</div>
              <div class="msg-text">{{msg.message}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="send-msg">
        <div class="send-inner">
          <div class="user-message">
              <input placeholder="type your message here.." class="input" v-model="message" type="text" @keyup.enter="pushMessage" >
          </div>
          <button v-on:click="pushMessage" :style="message.length>0?'background-color:#27ae60':''" class="send-btn"><img src="images/send_icon.svg" loading="lazy" width="25" alt=""></button>
        </div>
      </div>
    </div>
    <!-- main chat -->


    <!-- popup chat
        <div id="wholeChatWindow" class="chat-popup">
            <div class="chat-pop-header">
                <div class="pop-head-left">
                    <div class="clicker" @click="showUsers">
                        <img src="images/arrow.svg" loading="lazy" alt="" class="back-arrow">
                    </div>
                    <div class="text-block-35">Rita Bradshaw</div>
                </div>
                <div class="clicker" @click="toggleChat">
                    <img src="images/arrow-down.svg" loading="lazy" alt="" class="arrow-down">
                </div>
                <div class="clicker" @click="removeChat">
                    <img src="images/close.svg" loading="lazy" alt="" class="close">
                </div>
            </div>
            <div id="chatWindow">
                <div class="pop-chat-div">
                    <div class="chat-content-popup">
                        <div v-for="msg in messages" v-bind:key="msg.id" >
                            <div v-if="msg.from==authUserId" class="message2">
                            <div class="msg-content2">
                                <div class="msg-text2">{{msg.message}}</div>
                            </div>
                            </div>

                            <div v-else class="message1">
                            <div class="msg-content">
                                <div class="msg-sender">{{msg.fromName}}</div>
                                <div class="msg-text">{{msg.message}}</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="send-msg-popup">
                    <div class="send-inner">
                    <div class="user-message">
                        <input placeholder="type your message here.." class="input" v-model="message" type="text" @keyup.enter="pushMessage" >
                    </div>
                    <button v-on:click="pushMessage" class="send-btn"><img src="images/send_icon.svg" loading="lazy" width="25" alt=""></button>
                    </div>
                </div>
            </div>
            <div id="usersWindow" class="chat-div-users">
              
              <div v-for="user in users" v-bind:key="user.id" class="online-member clicker" @click="chatWithUser">
                <div class="live-now-content">
                  <div class="live-now-left">
                    <div class="green-chat-dot"></div>
                    <div class="live-text">
                      <div class="chat-text-1">{{user.fullName}}</div>
                    </div>
                  </div>
                </div>
              </div>                        
                                    
            </div>
        </div>
     popup chat -->

             <!-- show live users -->
            <div id="usersWindow" class="chat-show-users">
              <div class="chat-header">
                <div class="text-block-10">Users Online</div>
                <div class="clicker" @click="closeLiveUsers()"><img src="images/close_white.svg" loading="lazy" width="24" alt="">
                </div>
              </div>
              <div class="chat-content-users">
             
                <div v-for="user in usersInActiveRoom" v-bind:key="user.id"  class="online-member clicker" >
                  <div class="live-now-content">
                    <div class="live-now-left">
                      <div :class="['chat-dot',user.live?'live':'busy']"></div>
                      <div class="live-text">
                        <div class="chat-text-1">{{user.fullName}}</div>
                      </div>
                    </div>
                  </div>
                </div>                        
              
              </div>                  
            </div>
             <!--  show live users -->

    <!-- mobile chat -->
      <div class="mobile-chat-button" @click="toggleMobileChat"><img src="images/msng_icon.svg" loading="lazy" width="36" alt=""></div>

    

      
    <!-- mobile chat -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LiveNow from '../header/LiveNow.vue'
import User from '../header/User.vue'

export default {
    name: 'Header',
    data() {
        return {
            message: ""
        }
    },
    components: {
        LiveNow,
        User
    },
    methods: {
        pushMessage() {
            if (this.message == null || this.message== "") return;
            const msgData = {
                message: this.message
            }
            console.log(msgData)
            this.$store.dispatch('sendMessage', msgData.message)
            this.message = ""
        },
        showPopup() {
            var wholeChat = document.getElementById("wholeChatWindow")
            var navChat = document.getElementById("navChatWindow")
            navChat.style.display = "none"
            wholeChat.style.display = "block"
        },
        toggleChat() {
            var chat = document.getElementById("chatWindow")
            var users = document.getElementById("usersWindow")
            if (chat.style.display === "none" && users.style.display === "none") {
                chat.style.display = "block"
                users.style.display = "none"
            } else if (chat.style.display === "block") {
                chat.style.display = "none"
                users.style.display = "none"
            }else if (users.style.display === "flex") {
                chat.style.display = "none"
                users.style.display = "none"
            } else {
                chat.style.display = "block"
            }
        },
        removeChat() {
            var wholeChat = document.getElementById("wholeChatWindow")
            var navChat = document.getElementById("navChatWindow")
            wholeChat.style.display = "none"
            navChat.style.display = "flex"
        },
        showUsers() {
            var users = document.getElementById("usersWindow")
            var chat = document.getElementById("chatWindow") 
            users.style.display = "flex" 
            chat.style.display = "none"                
        },
        chatWithUser() {
            var users = document.getElementById("usersWindow")
            var chat = document.getElementById("chatWindow")
            users.style.display = "none" 
            chat.style.display = "flex"  
        },
        toggleMobileChat() {
            var mainChat = document.getElementById("mainChat");
            mainChat.classList.toggle("mobile");            
        },
        openLiveUsers() {
            var usersOnline = document.getElementById("usersWindow")
            usersOnline.style.display = "flex"
        },
        closeLiveUsers() {
            var usersOnline = document.getElementById("usersWindow")
            usersOnline.style.display = "none"
        },
        goToSection(val){
          this.$emit("go-section", val)
        }   
    
    },
    computed: {
      auth() {
        return this.$store.getters.isAuthenticated
      },
      ...mapGetters(["messages","activeRoom","users"]),
         authUserId() {
           return this.$store.state.user.id;
         },
         usersInActiveRoom(){
           var activeId=this.activeRoom.id;
           if (activeId == 0) return this.users;
           return this.users.filter(u => u.lastRoom==activeId);
         }
    },
    updated(){
      var chatScroll = document.getElementById('chatContentScroll');
      if (chatScroll!=null) chatScroll.scrollTop = chatScroll.scrollHeight
    },
}
</script>

<style>

</style>