<template>

    <div class="footer">
        <div class="footer-content">
        <a href="/#/lobby" aria-current="page" class="summit-logo w-inline-block w--current"><img src="images/logo.svg" loading="lazy" width="240" alt=""></a>
        <div class="contact-email">
            <a href="tel:+385994773878" class="contact-email-in w-inline-block"><img src="images/Vector.svg" loading="lazy" alt="">
          <div class="number-email">+385 99 201 3087</div>
            </a>
            <a href="mailto:info@bookingmanagersummit.com" class="contact-email-in w-inline-block"><img src="images/mail.svg" loading="lazy" alt="">
          <div class="number-email">info@bookingmanagersummit.com</div>
            </a>
        </div>
        <div class="footer-social">
            <a href="https://www.facebook.com/bookingmanagersummit/" target="_blank" class="social-button w-inline-block"><img src="images/facebook-1.svg" loading="lazy" alt=""></a>
            <a href="https://www.linkedin.com/showcase/booking-manager-summit" target="_blank" class="social-button w-inline-block"><img src="images/linkedin-1.svg" loading="lazy" alt=""></a>
            <a href="https://www.instagram.com/bookingmanagersummit/" target="_blank" class="social-button w-inline-block"><img src="images/instagram-sketched-1.svg" loading="lazy" alt=""></a>
        </div>
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>