<template>
  <div class="section">
    <img src="images/8121f07955987b9efa8a366d45b54c11.jpg" loading="lazy" srcset="images/8121f07955987b9efa8a366d45b54c11-p-500.jpeg 500w, images/8121f07955987b9efa8a366d45b54c11-p-800.jpeg 800w, images/8121f07955987b9efa8a366d45b54c11-p-1600.jpeg 1600w, images/8121f07955987b9efa8a366d45b54c11.jpg 1920w" sizes="100vw" alt="" class="image-5">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>