<template>
  <div class="partners">
    <div class="partners-content">
      <div class="partners-title-main">
        <h2 class="content-heading">Partners</h2>
      </div>
      <div class="partners-logos">
        <div class="partner-logo"><img src="images/ncp-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/ath-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/otp-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/adri-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/aci-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/hilton.png" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/ist-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/dew-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/pan-logo.jpg" loading="lazy" width="" alt=""></div>
        <div class="partner-logo"><img src="images/seal-logo.jpg" loading="lazy" width="" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>