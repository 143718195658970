<template>
    <div class="workshop">
    <div class="workshop-content-div">
      <div class="workshop-content-left"><img src="images/DD-img.jpg" loading="lazy" srcset="images/DD-img-p-800.jpeg 800w, images/DD-img-p-1080.jpeg 1080w, images/DD-img.jpg 1181w" sizes="(max-width: 991px) 85vw, 62vw" alt="">
        <div class="workshop-title">
          <div class="workshop-title1">
            <div class="workshop-owner">Premium</div>
            <h2 class="workshop-name1">mmk booking system</h2>
          </div>
          <div class="premium-btn">
            <div>
              <div class="mmk-txt">Premium</div>
            </div>
          </div>
        </div>
        <div class="workshop-details">
          <div class="details">About ACI:</div>
          <div class="details-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
        </div>
        <div class="assets">
          <div class="download">Download Assets:</div>
          <div class="assets-div"><img src="images/files_icon.svg" loading="lazy" alt="">
            <div class="asset-txt">MMK Booking Manager Brochure<br></div>
          </div>
          <div class="assets-div"><img src="images/files_icon.svg" loading="lazy" alt="">
            <div class="asset-txt">MMK Booking Price List<br></div>
          </div>
        </div>
      </div>

      <MMKPremiumChat></MMKPremiumChat>

    </div>
  </div>
</template>

<script>
import MMKPremiumChat from '../chat/MMKPremiumChat.vue'


export default {
  name: 'MMKPremiumContent',
    components: {
        MMKPremiumChat
    }

}
</script>

<style>

</style>