<template>
<div class="nav-inner-div">
        <div data-hover="" data-delay="0" class="nav-drop w-dropdown">
            <div class="nav-link w-dropdown-toggle">
                <div v-if="email" class="account-text">{{ email }}</div>
                <div v-else class="account-text">No User</div>
                <div class="account-active-dot"></div>
            </div>
            <nav class="status-list w-dropdown-list">
                <div class="user-div">
                <div class="status-title">
                    <div @click="signOut" class="chat-title clicker">Log out</div>
                </div>
                <!-- <div class="user-status">
                    <div class="status-content">
                    <div class="green-dot-pick"></div>
                    <div class="status-pick">online</div>
                    </div>
                </div>
                <div class="user-status">
                    <div class="status-content">
                    <div class="yellow-dot-pick"></div>
                    <div class="status-pick">busy</div>
                    </div>
                </div>
                <div class="user-status">
                    <div class="status-content">
                    <div class="red-dot-pick"></div>
                    <div class="status-pick">away</div>
                    </div>
                </div> -->
                <!-- <div class="logout-div">
                    <button @click="signOut" class="logout">Log out</button>
                </div> -->
                </div>
            </nav>
        </div>
</div>
</template>

<script>

export default {
    data() {
        return {
            
        }
    },
    methods: {
        // ovo spojiti na logout iz store.js-a
        signOut() {
           this.$store.dispatch('logout')
        }
    },
    computed: {
      email () {
        return !this.$store.getters.user ? false : this.$store.getters.user.email
      }
    },
    created () {
     // this.$store.dispatch('fetchUser')
    }
}
</script>

<style>

</style>