<template>
        <div class="form-div">
                <h1 class="form-main-title">Welcome!</h1>
                <div class="form-main-subtitle">Join Us by entering the information below.</div>
                <div if="errorMessages" class="text-block-29-red">{{errorMessages}}</div>
                <div class="form-block w-form">
                    <form @submit.prevent="onSubmit" id="email-form" name="email-form" data-name="Email Form">
                        <label for="email" class="field-label-2">Email:</label>
                        <input v-model="email" type="email" class="w-input" maxlength="256" name="name" data-name="Name" placeholder="" id="email" required="">
                        <label for="password" class="field-label">Password:</label>
                        <input v-model="password" type="password" class="w-input" maxlength="256" name="password" data-name="password" placeholder="" id="password" required="">
                        <button type="submit" class="button w-button">Login</button>
                    </form>
                </div>
                <!-- <a href="/#/lobby" class="button w-button">JOIN</a>  -->
                              
        </div>        
</template>

<script>

import { mapGetters } from "vuex";
export default {
    data() {
        return {
            email: "",
            password: '',
            test1:"It works"
        }
    },
    methods: {
        onSubmit() {
            this.login(this.email,this.password);
        },
        login(email,password){
            const auth = {
                username: email,
                password: password
            }
            this.$store.dispatch('login', auth)
            //this.$router.replace({ name: "Lobby" })
        }
    },
    computed: {
         ...mapGetters(["isAuthenticated", "errorMessages"])
    },mounted() {

        var email = this.$route.query.email;
        var password = this.$route.query.password;
        if (password!=null){
            this.login(email,password);
        }

    },
    watch: {
       isAuthenticated (newValue,oldValue){
            if(newValue==true&&oldValue==false){
                this.$router.replace({ name: "Lobby" })
            }
        }
    }
}
</script>

<style>

</style>