<template>
    <div class="hero-premium">
        <div class="div-block-2-copy">
        <h1 class="main-heading">Market Leader in creating booking software</h1>
        <div class="text-block-38">MMK Booking Manager</div>
        <div class="breadcrumb"><img src="images/lobby-icon.svg" loading="lazy" width="20" height="20" alt="" class="pin">
            <div class="web-location">Premium</div>
        </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>