<template>

    <div>

  
    <Header @go-section="goToSections"></Header>

    <LobbyHero></LobbyHero>

    <ThankYou></ThankYou>
  
    <Schedule></Schedule>
  
    <Partners></Partners>

    <Footer></Footer>

    </div>
  

</template>

<script>
import Header from '../components/includes/Header.vue'
import Footer from '../components/includes/Footer.vue'
import Partners from '../components/subcomponents/Partners.vue'
import Schedule from '../components/subcomponents/Schedule.vue'
import LobbyHero from '../components/subcomponents/LobbyHero.vue'
import ThankYou from '../components/subcomponents/ThankYou.vue'


import { Minxin } from '../mixin'

export default {
    name: 'Lobby',
    components: {
    Header,
    Footer,
    Partners,
    Schedule,
    LobbyHero,
    ThankYou,
},
    computed: {
      email () {
        return !this.$store.getters.user ? false : this.$store.getters.user.email
      }
    },
    created () {
     //this.$store.dispatch('fetchUser')
    },
    mounted() {
        /* var section=this.$router.currentRoute.hash.replace("#", "schedule");
        if (section){
          this.$nextTick(()=> window.document.getElementById("schedule").scrollIntoView());
        }  */
    },
    mixins: [Minxin]
}
   
  
</script>

<style>


</style>