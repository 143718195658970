<template>

   <div> 
    <Header @go-section="goToSections"></Header>
    
    <div class="hero-workshop" :style="{ backgroundImage: 'url(images/' + (selectedWorkshop.heroImg != null ? selectedWorkshop.heroImg : 'genericHero.jpg') + ')' }">
            <div class="div-block-2-copy">
            <h1 class="main-heading">{{selectedWorkshop.title}}</h1>
            <div class="text-block-38">by {{selectedWorkshop.speaker}}</div>
            <a href="/#/lobby" class="breadcrumb clicker"><img src="images/left-arrow.svg" loading="lazy" width="20" height="20" alt="" class="pin">
                <div class="web-location">Back to Lobby</div>
            </a>
            </div>
    </div>
    <div class="workshop">
        <div class="workshop-content-div">
            <div class="workshop-content-left">
                <div class="main-img-div" >
                    <div v-if="videoStream!=null" class="iframe-container">
                        <iframe  width="560" height="315" :src="videoStream" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <img v-else :src="('images/'+selectedWorkshop.mainImg)" loading="lazy" alt="">
                </div> 
                <div class="workshop-title">
                <div class="workshop-title1">
                    <div class="workshop-owner">{{selectedWorkshop.title}}</div>
                    <h2 class="workshop-name1">{{selectedWorkshop.speaker}}</h2>
                </div>
                <div v-if="selectedWorkshop.room !='Summit+'">
                    <div class="btn-workshop" v-if="selectedWorkshop.tag!='Premium'">
                    <div class="regular-txt">{{selectedWorkshop.tag}}</div>
                    </div>
                    
                    <!-- <div class="view-workshop" v-if="activeRoom.zoom!=''">
                        <a :href="activeRoom.zoom" target="_blank" class="button-camera w-button"><img style="margin-right:10px" src="images/video_icon.svg" loading="lazy" width="20" height="20" alt="">Join Video Chat</a>
                    </div> -->
                    
                </div>
                </div>
                <div class="workshop-abouts" v-if="selectedWorkshop.tag!='Premium'">
                <div class="info-div">
                    <div class="subject-info">date:</div>
                    <div class="subject-info2">{{ formatDate(selectedWorkshop)}}</div>
                </div>
                <div class="info-div" v-if="selectedWorkshop.room !='Summit+'">
                    <div class="subject-info">time:</div>
                    <div class="subject-info2">{{selectedWorkshop.startTime}} - {{selectedWorkshop.endTime}}</div>
                </div>
                <div class="info-div" v-if="selectedWorkshop.room !='Summit+'">
                    <div class="subject-info">type:</div>
                    <div class="subject-info2">{{selectedWorkshop.level}}</div>
                </div>
                <div class="info-div">
                    <div class="subject-info">stage:</div>
                    <div class="subject-info2">{{selectedWorkshop.room}}</div>
                </div>
                </div>
                <div class="workshop-details">
                <div class="details">Details:</div>
                <div class="details-info">{{selectedWorkshop.details}}</div>
                </div>
                <div class="workshop-details"  v-if="selectedWorkshop.aboutSpeaker!=null&&selectedWorkshop.aboutSpeaker!=''" >
                <div class="details">About Speaker:</div>
                <div v-html="selectedWorkshop.aboutSpeaker" class="details-info"></div>
                </div>
                <div v-if="selectedWorkshop.assets!=null&&selectedWorkshop.assets.length>0" class="assets">
                <div class="download">Download Assets:</div>
                <div v-for="asset in selectedWorkshop.assets" v-bind:key="asset" class="assets-div">
                    <img src="images/files_icon.svg" loading="lazy" alt="">
                    <div class="text"><a target="_blank" :href="'download/'+asset">{{asset}}</a></div>
                </div>
                </div>
            </div>    
        </div>
  </div>
    <Schedule></Schedule>
    <Partners></Partners>
    <Footer></Footer>
   </div>

</template>

<script>
import { mapGetters } from "vuex";
import Header from '../components/includes/Header.vue'
import Footer from '../components/includes/Footer.vue'
import Partners from '../components/subcomponents/Partners.vue'
import Schedule from '../components/subcomponents/Schedule.vue'
import { Minxin } from "../mixin";

export default {
    name: 'Workshop',
    data() {
        return {
            
        }
    },
    components: {
        Header,
        Footer,
        Partners,
        Schedule
    },
    computed: {
         ...mapGetters(["selectedWorkshop","liveNow","activeRoom"]),
         videoOn(){
           return this.selectedWorkshop.liveNow&&this.activeRoom.video!='';
        },
        videoStream(){
            if (this.videoOn){
                return this.activeRoom.video;
            }
            if (this.selectedWorkshop.replayVideo!=null&&this.selectedWorkshop.replayVideo!=''){
                return this.selectedWorkshop.replayVideo;
            }
            return null;
        }
    },
    methods: {
       /*  formatDate(workshop) {
            var d = new Date(workshop.date)
            return d.getDate()+" Nov";
        } */
    },
    watch: {

    },
    mixins: [Minxin]
    

}
</script>

<style>

</style>