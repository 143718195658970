<template>

  <div id="schedule" class="schedule">

    <div class="schedule-content">

    
      <div class="schedule-title-div">
        <h2 class="content-heading">Schedule</h2>
        <div class="my-schedule clicker" @click="showFavorites()" ><img :src="'images/icon_save'+(viewFavorites?'d':'')+'.svg'" loading="lazy" alt="">
          <div class="schedule-text" >my schedule</div>
        </div>
      </div>

      <div class="filter">
        <div class="filter-text">Filter:</div>
        <div @click="toggleSelectedTag(tag)" v-for="tag in tags" :key="tag.tagName" :class="['btn',tag.class,isSelectedTag(tag.tagName)?'selected':'']" >
          <div>{{tag.tagName}}</div>
        </div>
      </div>
        <!-- tab schedule -->
        <div data-duration-in="300" data-duration-out="100" class="tabs-schedule w-tabs">
          <div class="w-tab-menu">
           
               <a v-for="day in days" v-bind:key="day.day" @click="selectedDay=day.day" :class="'tab w-inline-block w-tab-link '+((day.day==activeDay.day)?'w--current':'')" :id="(day.tabLabel === 'Summit+') ? 'summit-plus' : ''">
                <div v-if="!day.tabLabel" class="day">
                  day {{day.day}}
                </div>
                <div v-else class="day">
                  {{day.tabLabel}}
                </div>
              </a>
            
          </div>

          <div class="daily-schedule">
                <div class="daily-title">
                  <div class="date">{{activeDay.dateLabel}}</div>
                </div>
                <template v-for="workshop in workshops" >
                  <div :key="workshop.id" v-if="isActive(workshop)" :class="'workshop-'+colorForTagName(workshop.tag)" >
                    <div class="workshop-schedule">
                      <div :class="'schedule-info ' + (summitPlusRoom(workshop) ? 'justify-content-evenly' : '')">
                        <div v-if="!summitPlusRoom(workshop)" class="time-of">
                          <div class="time-of-txt">{{workshop.startTime}} - {{workshop.endTime}}</div>
                        </div>
                        <div v-if="hasRoom(workshop) && !summitPlusRoom(workshop)" class="level-of">
                          <div v-if="hasRoom(workshop)" class="level-of-txt">type:</div>
                          <div class="level-of-txt2">{{workshop.level}}</div>
                        </div>
                        <div v-if="hasRoom(workshop)" class="level-of">
                          <div class="level-of-txt">stage:</div>
                          <div class="level-of-txt2">{{workshop.room}}</div>
                        </div>
                        <div class="speaker-info">
                          <div class="workshop-name">{{workshop.title}}</div>
                          <div v-if="hasRoom(workshop)" class="speaker-name-txt">by {{workshop.speaker}}</div>
                        </div>
                      </div>
                      <div v-if="hasRoom(workshop)" class="favorite-view"><img class="clicker" style="margin-right:10px" :src="'images/icon_save'+(isFavorite(workshop)?'d':'')+'.svg'" 
                      loading="lazy" width="24" alt="" @click="toggleFavorite(workshop)" ><a v-if="workshop.replayVideo==null||workshop.replayVideo==''" v-on:click="selectWorkshop(workshop)"
                      v-bind:class="{'view-live':workshop.liveNow, 'view-not-live':!workshop.liveNow, 'w-button':true}">view</a><a  v-else class="w-button replay" v-on:click="selectWorkshop(workshop)"><img class="clicker" style="margin-right:10px" src="images/replay_icon.svg" loading="lazy" width="24" alt="">replay
                      </a>
                      </div>
                    </div>
                  </div>
                </template>

                <br />
              </div>
        </div>
        <!-- tab schedule -->
       
      
    </div>
    <div class="hidden-filler-div"></div>
  </div>
</template>

<script>

function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

import { mapGetters } from "vuex";
import { Minxin } from '../../mixin';
export default {
    data() {
      return {
        tags: [
                {
                    tagName: "All",
                    tagColor: "black",
                    class: "btn-all",
                },
                {
                    tagName: "Marketing",
                    tagColor: "green",
                    class: "btn-marketing",
                    value: 'marketing'
                },
                {
                    tagName: "Finance",
                    tagColor: "blue",
                    class: "btn-finance",
                    value: 'finance'
                },{
                    tagName: "Sales",
                    tagColor: "yellow",
                    class: "btn-sales",
                    value: 'sales-crm'
                },{
                    tagName: "Booking Manager",
                    tagColor: "red",
                    class: "btn-bm",
                    value: 'mmk'
                },
                {
                    tagName: "Panel",
                    tagColor: "red",
                    class: "btn-mmk",
                    value: 'mmk'
                },
                {
                    tagName: "CRM",
                    tagColor: "purple",
                    class: "btn-panel",
                    value: 'panel'
                }
            ],
      
        days: [
          {day:1,date:"8/11/2023",dateTime:new Date(2024,10,6),dateLabel:"06 Nov"},
          {day:2,date:"9/11/2023",dateTime:new Date(2024,10,7),dateLabel:"07 Nov"},
          {day:3,date:"10/11/2023",dateTime:new Date(2024,10,8),dateLabel:"08 Nov"},
          //{day:4,date:"11/11/2023",dateTime:new Date(2023,10,11),dateLabel:"11 Nov", tabLabel:"Summit+"},

        ],
        selectedDay : null,
        selectedTag : null,
        viewFavorites: false
      }

    },
    computed: {
         ...mapGetters(["workshops","liveNow","favorites"]),
         activeDay(){
           if (this.selectedDay==null){
            var today = new Date();
            var activeDay = 1;
            this.days.forEach(day => {if(sameDay(today,day.dateTime)) {activeDay=day.day}});
            return this.days[activeDay-1];
           }
           return this.days[this.selectedDay-1];
          }
    },
    methods: {
      selectWorkshop(workshop){
        this.$store.commit('storeSelectedWorkshop', workshop);
        this.$router.push({ name: 'Workshop' });
        window.scrollTo(0,0);
      },
      toggleSelectedTag(tag){
        var alreadySelected = this.selectedTag==tag;
        this.selectedTag=alreadySelected?null:tag;
      },
      isActiveDay(date){
        return sameDay(this.activeDay.dateTime,date);
      },
      isActiveTag(tagName){
        if (tagName=="Premium") return false
        return this.selectedTag==null||
        this.selectedTag.tagName=="All"||
        this.selectedTag.tagName==tagName;
      },
      isSelectedTag(tagName){
        if (this.selectedTag==null){
          return tagName=="All";
        } 
        return this.selectedTag.tagName==tagName;
      },
      isActive(workshop){
        return this.isActiveTag(workshop.tag)
              &&this.isActiveDay(new Date(workshop.date))
              &&(!this.viewFavorites||this.isFavorite(workshop))
      },
      hasRoom(workshop){
        return workshop.room != ''&& workshop.room != null;
      },
      summitPlusRoom(workshop){
        return workshop.room === "Summit+";
      },
      colorForTagName(tagName){
        var color = "black";
        this.tags.forEach( tag => {if(tag.tagName==tagName) {color=tag.tagColor}} );
        return color;
      },
      getFavoriteIndex(workshop){
          var favIndex=-1;
          this.favorites.forEach( (fav,index) => {
            if (workshop.id==fav) { favIndex=index;}
          });
          return favIndex;
      },
      isFavorite(workshop){
        return this.getFavoriteIndex(workshop)>-1;
      },
      toggleFavorite(workshop){
        var favs = [...this.favorites];
        var index = this.getFavoriteIndex(workshop);
        if (index==-1){
          favs.push(workshop.id);
        } else {
          favs.splice(index,1);
        }
        this.$store.commit('storeFavorites', favs);
      },
      showFavorites(){
        this.viewFavorites = !this.viewFavorites;
      },
      showButtons(){
        this.viewButtons = !this.viewButtons;
      }
    },
    mixins: [Minxin]
}
</script>

<style>

</style>