<template>
  <div class="hero">
    <div class="hero-content">
      <h1 class="main-heading">Welcome to Lobby</h1>
      <div class="breadcrumb"><img src="images/lobby-icon.svg" loading="lazy" width="20" height="20" alt="" class="pin">
        <div class="web-location">Lobby</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>