<template>
<div class="nav-inner-div live-now-nav">
  <div data-hover="" data-delay="0" class="nav-drop w-dropdown">
          <div v-if="liveNow.length>0" class="nav-link w-dropdown-toggle"><img src="images/icon_livenow_bell.svg" loading="lazy" width="20" alt="">
            <div class="nav-text">live now</div>
          </div>
          <nav class="live-list w-dropdown-list">
            <div class="live-div">

                <div class="live-now">Live Now</div>
                  <div :class="{'live-now-view':isLive(workshop), 'div-none':!isLive(workshop)}" v-for="workshop in liveNow" :key="workshop.id">
                    <div class="live-now-content">
                      <div class="live-now-left">
                        <div class="green-dot-live"></div>
                        <div class="live-text">
                          <div class="live-text-1">{{workshop.title}}</div>
                          <div class="live-text-2">{{workshop.speaker}}</div>
                          <div class="timer-full">
                            <div class="live-timer" :style="'width:'+workshop.progress+'%'"></div>
                          </div>
                        </div>
                      </div>
                      <div v-if="workshop.title!='BREAK'" class="live-button"><a v-on:click="selectWorkshop(workshop)" class="btn-text w-button">view</a></div>
                    </div>
                  </div>
              
            </div>
          </nav>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
         ...mapGetters(["workshops","liveNow"]),
    },
    methods: {
      selectWorkshop(workshop){
        this.$store.commit('storeSelectedWorkshop', workshop);
        this.$router.push({ name: 'Workshop' });
        //.catch(()=>{});
      }, 
      isLive(workshop){
        return "Premium"!=workshop.tag;
      }

    }

}
</script>

<style>

</style>